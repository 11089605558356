const scrollToSection   = (classname) => {
    window.scrollTo({
        top:      document.querySelector(classname).offsetTop,
        behavior: 'smooth'
    });
}

function navLinks() {
    let currentSection  = 'aboutMe';
    let sections        = document.querySelectorAll(".scrollableSection");
    let nav             = document.querySelector(".navbar");
    let navLinks        = document.querySelectorAll("nav .link");

    sections.forEach((section) => {
        let sectionTop      = section.offsetTop;
        let sectionHeight   = section.clientHeight;

        if (window.scrollY >= (sectionTop-(sectionHeight / 100))) {
            currentSection = section.getAttribute("id");
        }
    });

    navLinks.forEach((li) => {
        li.classList.remove("active");

        if (li.classList.contains(currentSection)) {
            li.classList.add("active");
            nav.scrollLeft = li.offsetLeft/2;
        }
    });
}

function Nav() {
    navLinks();

    window.addEventListener("scroll", () => {
        navLinks();
    });

    return (
        <nav className="navbar text-nowrap flex-nowrap position-fixed overflow-x-scroll overflow-y-hidden">
            <div>
                <ul>
                    <li onClick={() => scrollToSection('#aboutMe')} className="link aboutMe">
                        About Me
                    </li>
                    <li onClick={() => scrollToSection('#portfolio')} className="link portfolio">
                        Portfolio
                    </li>
                    <li onClick={() => scrollToSection('#currentProjects')} className="link currentProjects">
                        Current&#160;Projects
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Nav;