import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Nav from './layout/nav';
import AboutMe from "./layout/aboutMe";
import CurrentProjects from "./layout/currentProjects";
import Portfolio from "./layout/portfolio";
import Footer from "./layout/footer";

function App() {

    /*** BEGIN Site Redirects ***/
    const gaseousRedir  = ['gaseous.org', 'www.gaseous.org', 'gaseo.us', 'www.gaseo.us', 'gaseous.io', 'www.gaseous.io'];
    const jrogRedir     = ['jrog.io', 'www.jrog.io'];
    const zoomRedir     = ['zoom.jrog.io'];

    if (gaseousRedir.includes(window.location.hostname)) {
        window.location.href = 'https://github.com/jlr2k8/gaseous';
    } else if (jrogRedir.includes(window.location.hostname)) {
        window.location.href = 'https://www.joshlrogers.com';
    } else if (zoomRedir.includes(window.location.hostname)) {
        window.location.href = 'https://zoom.us/j/2153348585';
    } else {
    /*** END Site Redirects ***/
        return (
            <div className="App">
                <Nav />

                <div className="container">
                    <div id="content" className="page">
                        <AboutMe />
                        <Portfolio />
                        <CurrentProjects />
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default App;